import { Ionicons } from '@expo/vector-icons'
import { Pressable } from 'react-native'
import { colors } from '../../../../../Helpers/variables'
import useSound from '../../../../../Hooks/useSound'

export default function TrackChangeButton({ size = 30, type }) {
	const { currentPlayingTrack, handleTrackChange } = useSound()

	return (
		<Pressable disabled={currentPlayingTrack === null} onPress={() => handleTrackChange(type)}>
			<Ionicons
				name={type === 'next' ? 'play-skip-forward-sharp' : 'play-skip-back-sharp'}
				size={size}
				color={currentPlayingTrack === null ? colors.secondary : 'white'}
			/>
		</Pressable>
	)
}
