import { Ionicons } from '@expo/vector-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Modal, Platform, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native'
import DraggableFlatList, { ScaleDecorator } from 'react-native-draggable-flatlist'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import uuid from 'react-native-uuid'
import { playlistSortByName } from '../../Helpers/sortArray'
import { USER_PLAYLIST_URL, colors } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import Track from '../../Screens/TracksScreen/components/Track'
import { SecondaryButton } from '../Buttons/Button'
import Loader from '../Loader/Loader'
import { Heading } from '../Typography/Typography'
import { NotificationModal } from './NotificationModal'
import * as Sentry from 'sentry-expo'

const QueueModal = ({ isModalOpen, setModalOpen, queueTrackList, setQueueTrackList, currentPlayingTrack }) => {

	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [currentQueue, setCurrentQueue] = useState([])

	useEffect(() => {
		handleResize()
	}, [width])

	useEffect(() => {
		let displayQueue = []
		let playingItem = queueTrackList?.find(item => item.track === currentPlayingTrack)
		if (playingItem) {

			//display the items after the current playing up to the last item
			const playingIndex = queueTrackList.indexOf(playingItem)
			queueTrackList?.map((item, index) => {
				if (index > playingIndex) {
					displayQueue.push(item)
				}
			})

			//last item is playing and nothing is next, then display the complete list again
			if (displayQueue.length === 0) {
				queueTrackList?.map((item) => {
					if (item !== playingItem) {
						displayQueue.push(item)
					}
				})
			}
			setCurrentQueue(displayQueue)
		} else {
			setCurrentQueue(queueTrackList)
		}

	}, [])
	
	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}
	
	const styles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			margin: 20,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: 30,
			paddingBottom: 45,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			minWidth: isWideScreen ? 700 : isSmallScreen ? 240  : 360,
			maxWidth: isWideScreen ? 700 : isSmallScreen ? 240  : 360,
			height: '90%'
		},
		label: {
			marginBottom: 20,
			color: 'white',
			fontSize: 20,
			marginTop: 10
		},
		errorMessage: {
			color: 'red',
			marginBottom: 10
		}
	})
	
	const [userPlaylists, setUserPlaylists] = useState([])
	const api = useAxios()
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})
	const [isModalOpenState, setIsModalOpenState] = useState(isModalOpen)

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const changePosition = (from, to, data) => {

		//set currentQueue with the reordered list of not yet played. this is for re-rendering of list
		setCurrentQueue(data)

		let newQueue = []

		//get first the items that are DONE OR CURRENTLY playing; these are excluded in currentQueue
		queueTrackList?.map(item => {
			let isDonePlaying = currentQueue.indexOf(item) === -1
			if (isDonePlaying) {
				newQueue.push(item)
			}
		})

		//then add the DATA which is the new order of queue
		data.map(item => {
			newQueue.push(item)
		})

		//set queueTrackList with the new order, replacing the old list
		setQueueTrackList(newQueue)
	}

	const renderItem = useCallback(
		({ item, index, drag, isActive }) => {
			return (
				<ScaleDecorator>
					{/* {item.visible && ( */}
					<TouchableOpacity
						onLongPress={drag}
						disabled={isActive}
						style={{ padding: 10, backgroundColor: isActive ? colors.accent : colors.secondary }}
					>
						<View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
							<Ionicons name="move" size={22} color={'white'} style={{ marginHorizontal: 10 }} />
							<Track
								edit={false}
								track={item.track}
								tracksList={currentQueue}
								playlistTrackId={item.id}
								playlistId={item.playlist}
								userPlaylists={userPlaylists}
								openNotifModal={openNotifModal}
								closeNotifModal={closeNotifModal}
								isRepeatAllTracks={false}
								item={item}
								trackIndex={index}
								isQueue={true}
							/>
						</View>
					</TouchableOpacity>
					{/* )} */}
				</ScaleDecorator>
			)
		},
		[currentQueue]
	)

	const handleModalClosing = () => {
		setIsModalOpenState(false)
		setModalOpen(false)
	}

	return (
		isModalOpenState && (
			<Modal
				animationType="slide"
				transparent={true}
				visible={isModalOpenState}
				onRequestClose={() => {
					setIsModalOpenState(false)
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Heading>Kø</Heading>
						<View
							style={{ flexDirection: 'row', marginTop: 20, height: '86%', overflowY: 'scroll' }}
							key={'akjdsfal' + uuid.v4()}
						>
							{currentQueue ? (
								<View>
									{currentQueue.length > 0 ? (
										<GestureHandlerRootView>
											<DraggableFlatList
												style={{ width: 600, minWidth: 300 }}
												data={currentQueue}
												onDragEnd={({ data, from, to }) => {
													changePosition(from, to, data)
												}}
												renderItem={renderItem}
												keyExtractor={item => item.id}
											/>
										</GestureHandlerRootView>
									) : (
										<Text style={{ color: 'white' }}>Ingen sange...</Text>
									)}
								</View>
							) : (
								<Loader bgColor={colors.secondary} />
							)}
						</View>
						<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
							<SecondaryButton
								title={'Luk'}
								onPress={ handleModalClosing }
								style={{ width: 180, margin: 10 }}
							/>
						</View>
					</View>
				</View>
				{notifModal.visible && (
					<NotificationModal
						title={notifModal.title}
						description={notifModal.description}
						visible={notifModal.visible}
						closeNotifModal={closeNotifModal}
						isDisappearing={notifModal.isDisappearing}
					/>
				)}
			</Modal>
		)
	)
}
export default React.memo(QueueModal)
