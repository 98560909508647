import { Ionicons } from '@expo/vector-icons'
import React, { useState, useEffect } from 'react'
import { Pressable, View, useWindowDimensions } from 'react-native'
import { colors, PLAYLIST_TRACKS_EDIT_URL } from '../../../../../Helpers/variables'
import useSound from '../../../../../Hooks/useSound'
import QueueModal from '../../../../Modals/QueueModal'
import useAxios from '../../../../../Hooks/useAxios'

const QueueButton = () => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const api = useAxios()

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const {
		currentPlayingTrack,
		queueTrackList,
		setQueueTrackList,
	} = useSound()
	const [queueModalOpen, setQueueModalOpen] = useState(false)

	const handleClickQueue = () => {
		if (currentPlayingTrack) {
			setQueueModalOpen(true)
		}
	}

	return (
		<View style={{ marginLeft: isWideScreen ? 10 : 0 }}>
			<Pressable disabled={currentPlayingTrack === null}>
				<Ionicons
					name="list-outline"
					size={20}
					style={{marginLeft: 3}}
					color={currentPlayingTrack ? 'white' : colors.secondary}
					onPress={() => handleClickQueue()}
				/>
			</Pressable>
			{queueModalOpen && (
				<QueueModal
					setModalOpen={setQueueModalOpen}
					isModalOpen={queueModalOpen}
					currentPlayingTrack={currentPlayingTrack}
					queueTrackList={queueTrackList}
					setQueueTrackList={setQueueTrackList}
				/>
			)}
		</View>
	)
}

export default React.memo(QueueButton)
