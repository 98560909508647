import React, { useEffect, useState } from 'react'

import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import axios from 'axios'
import { Image, ImageBackground, Pressable, Text, TouchableHighlight, View, StyleSheet, useWindowDimensions, Alert } from 'react-native'
import { Filter } from '../../../Components/Forms/Filter'
import { NotificationModal } from '../../../Components/Modals/NotificationModal'
import { TherapistModal } from '../../../Components/Modals/TherapistModal'
import { COPYPLAYLIST_ASSIGNED_URL, MUSICTHERAPISTS_URL, colors } from '../../../Helpers/variables'
import useLayout from '../../../Hooks/useLayout'
import useSound from '../../../Hooks/useSound'
import useTokens from '../../../Hooks/useTokens'

export default function PlaylistDetails({
	tracksList,
	hasCopyPlaylistBtn = false,
	edit,
	isTrackPositionChangeable,
	setEdit,
	isRepeatAllTracks = false,
	musicTherapistName,
	musicTherapistId,
	isHideTherapist = false,
	artistName = '',
	saveTracksPosition,
	copyMyPlaylistLabel = 'Lavet af Musicmind',
	sortByValues,
	onSortValueChange,
	sortValue,
	...props
}) {
	
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 450)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 450)
	}

	const detailsStyle = StyleSheet.create({
		title: {
			color: 'white', 	
			fontSize: 20, 
			fontWeight: 'bold', 
		},
		header: {
			color: colors.accent, 
			fontSize: 16, 
			fontStyle: 'italic',
			width: 100
		},
		data: {
			color: 'white', 
			fontSize: 16,
		}
	})

	const { photo, title, tracks, description } = props
	const { deviceType } = useLayout()

	const fallback = require('../../../assets/fallback_cat.png')
	const logo = require('../../../assets/icon.png')
	const { getTokens } = useTokens()
	const [therapistModal, setTherapistModal] = useState(false)
	const [therapistInfo, setTherapistInfo] = useState(undefined)
	const {
		handleTrackPlayer,
		handleClickShuffle,
		isShuffleActive,
		handleClickRepeat,
		repeatType,
		mediaPlayerAcquisition,
		currentPlayingTrack,
		setIsShuffleActive,
		queueTrackList,
		setQueueTrackList,
	} = useSound()
	const [playlistDuration, setPlaylistDuration] = useState('00:00')

	useEffect(() => {
		if (tracks) {
			// let duration = 0;
			// tracks.map(track => {
			// 	duration += track.length
			// })
			setPlaylistDuration('00:00')
		} else {
			setPlaylistDuration('00:00')
		}
	}, [playlistDuration])

	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const { isSidebarOpen } = useLayout()

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const handleCopyPlaylist = async () => {
		let is_allowed_to_be_removed = true
		let playlist = tracksList[0].playlist
		const { access } = await getTokens()

		axios
			.post(
				COPYPLAYLIST_ASSIGNED_URL,
				{ playlist, is_allowed_to_be_removed },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					openNotifModal('Tilføj spilleliste', 'Tilføj')
				} else {
					openNotifModal('Tilføj spilleliste', 'Kunne ikke tilføje ')
				}
			})
			.catch(err => {
				openNotifModal('Tilføj spilleliste', 'Kunne ikke tilføje ')
				console.log(err)
			})
	}

	const getMusicTherapistInfo = async () => {
		const { access } = await getTokens()
		axios
			.get(MUSICTHERAPISTS_URL + musicTherapistId, {
				headers: { Authorization: `JWT ${access}` }
			})
			.then(({ data }) => {
				if (data.length > 0) {
					setTherapistInfo(data[0])
					setTherapistModal(true)
				}
			})
			.catch(error => {
				console.log(error)
			})
		// .finally(() => setLoading(false))
	}

	const handlePlayingAll = (tracksList) => {
		let queueItems = []
		let firstValidTrack = tracksList.filter(item => item.track.track_file !== null && item.track.track_file !== undefined && item.track.track_file)?.[0].track
		let firstValidIndex = 0
		let counter = 0

		const exists = queueItems && queueItems.some(item => item.playlist === tracksList[0].playlist);

		if (!exists) {
			tracksList.map(item => {
				const isTrackFileValid = item.track.track_file !== null && item.track.track_file !== undefined && item.track.track_file !== '' 
				if (isTrackFileValid) {
					if (firstValidTrack && firstValidTrack === item.track) {
						firstValidIndex = counter
					}
					queueItems.push(item)
				}

				counter++
			})
		}

		setQueueTrackList(queueItems)
		handleTrackPlayer(firstValidTrack, tracksList, firstValidIndex)
	}
	
	const addToQueue = (tracksList) => {
		let queueItems = queueTrackList ? [...queueTrackList] : []
		
		const exists = queueItems?.some(item => item.playlist === tracksList[0]?.playlist);

		if (exists) { 
			Alert.alert('Tilføj til kø', 'Denne playliste er allerede i køen.')
		} else {
			tracksList.map(item => {
				const isTrackFileValid = item.track.track_file !== null && item.track.track_file !== undefined && item.track.track_file !== '' 
				if (isTrackFileValid) {
					queueItems.push(item)
				}
			})
			Alert.alert('Tilføj til kø', 'Succesfuldt tilføjet til kø.')
			setQueueTrackList(queueItems)
		}
	}

	return (
		<View
			style={{
				flexDirection: deviceType !== 1 || isSidebarOpen ? 'row' : 'column',
				alignItems: deviceType !== 1 ? 'center' : 'flex-start',
				marginBottom: 10,
				width: '100%'
			}}
		>
			<ImageBackground
				blurRadius={20}
				imageStyle={{ borderRadius: 15 }}
				source={photo ? { uri: photo } : fallback}
				style={{ height: 150, width: 150 }}
				resizeMode={'cover'}
			>
				<Image
					source={photo ? { uri: photo } : logo}
					style={{
						height: 100,
						width: 100,
						borderRadius: 50,
						marginLeft: 25,
						marginTop: 25,
						borderColor: colors.accent,
						borderWidth: 2
					}}
					resizeMode={'cover'}
				/>
			</ImageBackground>
			<View style={{ flex: 1, marginLeft: 10, marginTop: deviceType !== 1 ? 0 : 20 }}>
				<Text style={{marginBottom: 10}}>
					<View>
						<Text style={detailsStyle.header}>Titel:</Text>
					</View>
					<Text style={detailsStyle.title}>{title}</Text>
				</Text>
				{isHideTherapist === false ? (
					<View>
						{musicTherapistName ? (
							<TouchableHighlight
								onPress={() => {
									getMusicTherapistInfo()
								}}
							>
								<Text>
									<View>
										<Text style={detailsStyle.header}>Lavet af:</Text>
									</View>
									<Text style={detailsStyle.data}>{musicTherapistName}</Text>
								</Text>
							</TouchableHighlight>
						) : (
							<Text>
								<View>
									<Text style={detailsStyle.header}>Lavet af:</Text>
								</View>
								<Text style={detailsStyle.data}>{copyMyPlaylistLabel}</Text>
							</Text>
						)}
					</View>
				) : (
					<View>
						<Text>
							<View>
								<Text style={detailsStyle.header}>Lavet af:</Text>
							</View>
							<Text style={detailsStyle.data}>{artistName}</Text>
						</Text>
					</View>
				)}
				{
					description &&
						<View style={{flexDirection: 'row'}}>
							<View>
								<Text style={detailsStyle.header}>Beskrivelse:</Text>
							</View>
							<View style={{width: '90%'}}>
								<Text style={[detailsStyle.data, {textAlign: 'left'}]}>{description}</Text>
							</View>
						</View>
				}
				{/* <Text>
					<View>
						<Text style={detailsStyle.header}>Varighed:</Text>
					</View>
					<Text style={detailsStyle.data}>{playlistDuration}</Text>
				</Text> */}
				<Text>
					<View>
						<Text style={detailsStyle.header}>Antal numre:</Text>
					</View>
					<Text style={detailsStyle.data}>{tracks}</Text>
				</Text>
				<View style={{ width: '100%', flexDirection: 'row', marginTop: 20, alignItems: 'center', justifyContent: 'space-between' }}>
					<View style={{ width: '80%', flexDirection: 'row', alignItems: 'center' }}>
						<Pressable
							disabled={tracksList && tracksList.length == 0}
							onPress={() => {
								if (isRepeatAllTracks && repeatType === 'none') handleClickRepeat('repeatAllTracks')
								else if (!isRepeatAllTracks && repeatType === 'repeatAllTracks') handleClickRepeat('none')
								handlePlayingAll(tracksList)
								setIsShuffleActive(false)
							}}
						>
							<View
								style={{
									backgroundColor: colors.accent,
									borderRadius: 40,
									paddingVertical: 7,
									paddingHorizontal: 10,
									flexDirection: 'row',
									alignItems: 'center'
								}}
							>
								<Ionicons name="play-circle" size={16} style={{ marginRight: 5 }} color={colors.background} />
								<Text style={{ fontSize: 14, fontWeight: 'bold', color: colors.background }}>Afspil</Text>
							</View>
						</Pressable>
						{hasCopyPlaylistBtn && tracksList.length > 0 && (
							<Pressable onPress={() => handleCopyPlaylist()}>
								<View
									style={{
										backgroundColor: colors.accent,
										borderRadius: 40,
										paddingVertical: 7,
										paddingHorizontal: 10,
										flexDirection: 'row',
										alignItems: 'center',
										marginLeft: 10
									}}
								>
									<Ionicons name="copy" size={16} style={{ marginRight: 5 }} color={colors.background} />
									<Text style={{ fontSize: 14, fontWeight: 'bold', color: colors.background }}>Kopier</Text>
								</View>
							</Pressable>
						)}

						{isTrackPositionChangeable && !edit ? (
							<Pressable onPress={() => setEdit(true)}>
								<View
									style={{
										backgroundColor: colors.accent,
										borderRadius: 40,
										paddingVertical: isSidebarOpen ? 7 : 10,
										paddingHorizontal: 5,
										marginHorizontal: 10,
										flexDirection: 'row',
										// alignItems: 'center'

									}}
								>
									<Ionicons name="pencil-sharp" size={isSidebarOpen ? 18 : 13} style={{ marginRight: 3 }} color={colors.background} />
									<Text style={{ fontSize: isSidebarOpen ? 16 : 13, fontWeight: 'bold', color: colors.background, flexWrap: 'wrap' }}>
										Rediger Spilleliste
									</Text>
								</View>
							</Pressable>
						) : (
							<Pressable onPress={async () => {
								await saveTracksPosition(tracksList)
								setEdit(false)
							}}>
								<View
									style={{
										backgroundColor: colors.accent,
										borderRadius: 40,
										paddingVertical: 7,
										paddingHorizontal: 20,
										marginHorizontal: 10,
										flexDirection: 'row',
										alignItems: 'center'
									}}
								>
									<Ionicons name="pencil-sharp" size={18} style={{ marginRight: 5 }} color={colors.background} />
									<Text style={{ fontSize: 16, fontWeight: 'bold', color: colors.background }}>Gem</Text>
								</View>
							</Pressable>
						)}

						{/* <Pressable 
							disabled={tracksList && tracksList.length == 0}
							onPress={() => { addToQueue(tracksList) }}
						>
							<View
								style={{
									backgroundColor: colors.accent,
									borderRadius: 40,
									paddingVertical: isSidebarOpen ? 7 : 10,
									paddingHorizontal: 5,
									flexDirection: 'row', 
									marginLeft: isTrackPositionChangeable ? 0 : 5,
									// alignItems: 'center'

								}}
							>
								<Ionicons name="add-outline" size={isSidebarOpen ? 18 : 13} style={{ marginRight: 3 }} color={colors.background} />
								<Text style={{ fontSize: isSidebarOpen ? 16 : 13, fontWeight: 'bold', color: colors.background, flexWrap: 'wrap' }}>
									Føj til kø
								</Text>
							</View>
						</Pressable> */}
						{!isSmallScreen && mediaPlayerAcquisition.isShuffleBtnVisible && (
							<Pressable disabled={currentPlayingTrack === null}>
								<Ionicons
									name="shuffle"
									size={26}
									color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
									style={{ marginHorizontal: 5 }}
									onPress={() => handleClickShuffle()}
								/>
							</Pressable>
						)}
						{!isSmallScreen && mediaPlayerAcquisition.isRepeatBtnVisible && (
							<Pressable>
								{repeatType === 'one' ? (
									<MaterialIcons
										name="repeat-one"
										size={26}
										color={currentPlayingTrack === null ? colors.secondary : colors.accent}
										style={{ marginHorizontal: 5 }}
										onPress={() => handleClickRepeat(isRepeatAllTracks ? 'repeatAllTracks' : 'none')}
									/>
								) : (
									<Ionicons
										name="ios-repeat-sharp"
										size={26}
										color={currentPlayingTrack === null ? colors.secondary : repeatType === 'all' ? colors.accent : 'white'}
										style={{ marginHorizontal: 5 }}
										onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
									/>
								)}
							</Pressable>
						)}
					</View>
					{tracksList.length > 0 && sortByValues && (
						<View style={{ width: '20%', flexDirection: 'row', justifyContent: 'flex-end' }}>
							<Filter
								value={sortValue}
								items={sortByValues}
								onSortValueChange={onSortValueChange}
								defaultButtonText={sortValue}
								newItemLabelName={'Filter Feature'}
							/>
						</View>
					)}
				</View>
				{isSmallScreen && (
					<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
						{mediaPlayerAcquisition.isShuffleBtnVisible && (
							<Pressable disabled={currentPlayingTrack === null}>
								<Ionicons
									name="shuffle"
									size={26}
									color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
									style={{ marginHorizontal: 5 }}
									onPress={() => handleClickShuffle()}
								/>
							</Pressable>
						)}
						{mediaPlayerAcquisition.isRepeatBtnVisible && (
							<Pressable>
								{repeatType === 'one' ? (
									<MaterialIcons
										name="repeat-one"
										size={26}
										color={currentPlayingTrack === null ? colors.secondary : colors.accent}
										style={{ marginHorizontal: 5 }}
										onPress={() => handleClickRepeat(isRepeatAllTracks ? 'repeatAllTracks' : 'none')}
									/>
								) : (
									<Ionicons
										name="ios-repeat-sharp"
										size={26}
										color={currentPlayingTrack === null ? colors.secondary : repeatType === 'all' ? colors.accent : 'white'}
										style={{ marginHorizontal: 5 }}
										onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
									/>
								)}
							</Pressable>
						)}

							{/* <Pressable 
								disabled={tracksList && tracksList.length == 0}
								onPress={() => { addToQueue(tracksList) }}
							>
								<View
									style={{
										backgroundColor: colors.accent,
										borderRadius: 40,
										paddingVertical: isSidebarOpen ? 7 : 10,
										paddingHorizontal: 5,
										flexDirection: 'row', 
										marginLeft: isTrackPositionChangeable ? 0 : 5,
										// alignItems: 'center'

									}}
								>
									<Ionicons name="add-outline" size={isSidebarOpen ? 18 : 13} style={{ marginRight: 3 }} color={colors.background} />
									<Text style={{ fontSize: isSidebarOpen ? 16 : 13, fontWeight: 'bold', color: colors.background, flexWrap: 'wrap' }}>
										Føj til kø
									</Text>
								</View>
							</Pressable> */}
					</View>
				)}
			</View>
			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
			{therapistModal && (
				<TherapistModal modalVisible={therapistModal} setModalVisible={setTherapistModal} info={therapistInfo} />
			)}
		</View>
	)
}
