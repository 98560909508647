import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import Constants from 'expo-constants'
import { Image, ImageBackground, Pressable, Text, TouchableHighlight, useWindowDimensions, View } from 'react-native'

import { useEffect, useState } from 'react'
import useLayout from '../../../Hooks/useLayout'

// For Step by Step Walkthrough
import { CopilotStep, useCopilot, walkthroughable } from 'react-native-copilot'
import { TourModal } from '../../Modals/TourModal'

import LinkButton from './components/LinkButton'
import PlayButton from './components/TrackControls/PlayButton'
import TrackChangeButton from './components/TrackControls/TrackChangeButton'

import { useNavigation } from '@react-navigation/native'
import truncateStr from '../../../Helpers/truncateStr'
import { colors } from '../../../Helpers/variables'
import useSound from '../../../Hooks/useSound'
import AnnouncementsModal from '../../Modals/AnnouncementsModal'
import TermsConditionsModal from '../../Modals/TermsConditionsModal'
import TrackControls from './components/TrackControls'
const fallback = require('../../../assets/fallback_cat.png')
const logo = require('../../../assets/icon.png')
const WalkthroughableView = walkthroughable(View)

const Sidebar = props => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const navigation = useNavigation()

	const {
		handleClickShuffle,
		isShuffleActive,
		handleClickRepeat,
		repeatType,
		currentPlayingTrack,
	} = useSound()

	const { isSidebarOpen, toggleSidebar, deviceType, setIsShowHeaderTour } = useLayout()
	const [tourModal, setTourModal] = useState({
		title: undefined,
		description: undefined
	})
	const { copilotEvents } = useCopilot()

	useEffect(() => {
		//setting a function to handle the step change event
		copilotEvents.on('stepChange', handleStepChange)
		copilotEvents.on('stop', handleStop)

		return () => {
			copilotEvents.off('stop')
			copilotEvents.off('stepChange')
			copilotEvents.off('start')
		}
	}, [])

	const [image, setImage] = useState(undefined)

	useEffect(() => {
		if (currentPlayingTrack) {
			setImage(currentPlayingTrack.album_photo)
		}
	}, [currentPlayingTrack])

	const handleStepChange = step => {
		//Handler, in case we want to handle the step change
		console.log(`Current step is: ${step.order}`)
	}

	const handleStop = (isSkipTour = false) => {
		if (!isSkipTour) setIsShowHeaderTour(true)
		props.stop
	}

	return (
		<View
			style={{
				flex: 1,
				justifyContent: 'space-between',
				alignItems: 'flex-start'
			}}
		>
			<View style={{
				width: '100%',
				alignItems: isWideScreen || isSidebarOpen ? 'flex-start' : 'center',
				paddingHorizontal: !isWideScreen && isSidebarOpen ? 10 : (isWideScreen ? 25 : 0)
			}}
			>
				<View style={{ paddingHorizontal: 0, marginBottom: 20, marginTop: 10 }}>
					{deviceType !== 1 ? (
						<Image
							source={require('../../../assets/music_mind_logo.png')}
							style={{
								width: width < 800 ? 120 : 160,
								height: 60
							}}
							resizeMode={'contain'}
						/>
					) : (
						<Pressable onPress={toggleSidebar}>
							<Ionicons name={'ios-menu'} size={isWideScreen ? 30 : 25} color={'#ffffff'} />
						</Pressable>
					)}
				</View>

				<LinkButton open={isSidebarOpen} route="HomeScreen" title={'Hjem'} icon={'ios-home'} />
				<LinkButton
					open={isSidebarOpen}
					route="InternalContainerScreen"
					title={'Internt Miljø'}
					icon={'musical-notes-sharp'}
				/>
				<LinkButton open={isSidebarOpen} route="MyPlaylistsScreen" title={'Mine spillelister'} icon={'ios-albums'} />
				<LinkButton
					open={isSidebarOpen}
					route="CreatePlaylistScreen"
					title={'Opret Spilleliste'}
					icon={'ios-library-sharp'}
				/>
				<LinkButton
					open={isSidebarOpen}
					route="ImportFromThirdPartyModal"
					title={'Importer Spilleliste'}
					icon={'cloud-upload-sharp'}
					isModal={true}
					newItemLabelName={'Import Playlist Feature'}
				/>
				<Text style={{ fontSize: 10, color: 'white' }}>V {Constants.expoConfig.version}</Text>
			</View>
			{deviceType !== 1 || (!isWideScreen && isSidebarOpen) ? (
				<TrackControls CopilotStep={CopilotStep} WalkthroughableView={WalkthroughableView} />
			) : (
				<View
					style={{
						width: '100%',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexDirection: isSidebarOpen ? 'row' : 'column',
						paddingHorizontal: isWideScreen ? 25 : 0,
						paddingVertical: 10
					}}
				>
					{currentPlayingTrack && (
						<View style={{ alignItems: 'center' }}>
							<ImageBackground
								blurRadius={20}
								imageStyle={{ borderRadius: 0 }}
								source={image ? { uri: image } : fallback}
								style={{ width: '100%', alignItems: 'center' }}
								resizeMode={'cover'}
							>
								<Image
									source={image ? { uri: image } : logo}
									style={{
										height: 40,
										width: 40,
										borderRadius: 100,
										marginTop: '30%',
										borderColor: colors.accent,
										borderWidth: 1
									}}
									resizeMode={'cover'}
								/>
								<View style={{ paddingVertical: 10 }}>
									<Text style={{ color: 'white', textAlign: 'center', fontSize: 14 }}>
										{truncateStr(currentPlayingTrack.song_title, 20)}
									</Text>
									<TouchableHighlight
										onPress={() => {
											if (navigation) {
												navigation.navigate('ArtistTracks', {
													artistId: currentPlayingTrack.artist,
													photo: currentPlayingTrack.album_photo,
													title: currentPlayingTrack.artist_name
												})
											}
										}}
									>
										<Text style={{ color: 'white', textAlign: 'center', fontSize: 10 }}>
											{truncateStr(currentPlayingTrack.artist_name, 20)}
										</Text>
									</TouchableHighlight>
								</View>
							</ImageBackground>
						</View>
					)}
					<Pressable disabled={currentPlayingTrack === null}>
						<Ionicons
							name="shuffle"
							size={isWideScreen ? 26 : 22}
							color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
							style={{ marginHorizontal: 10 }}
							onPress={() => handleClickShuffle()}
						/>
					</Pressable>
					<TrackChangeButton size={isWideScreen ? 30 : 22} type={'prev'} />
					<PlayButton size={isWideScreen ? 55 : 30} />
					<TrackChangeButton size={isWideScreen ? 30 : 22} type={'next'} />
					<Pressable disabled={currentPlayingTrack === null}>
						{repeatType === 'one' ? (
							<MaterialIcons
								name="repeat-one"
								size={isWideScreen ? 26 : 22}
								color={currentPlayingTrack === null ? colors.secondary : colors.accent}
								// style={{ marginHorizontal: 10 }}
								onPress={() => handleClickRepeat(isRepeatAllTracks ? 'repeatAllTracks' : 'none')}
							/>
						) : (
							<Ionicons
								name="ios-repeat-sharp"
								size={isWideScreen ? 26 : 22}
								color={currentPlayingTrack === null ? colors.secondary : repeatType === 'all' ? colors.accent : 'white'}
								// style={{ marginHorizontal: 10 }}
								onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
							/>
						)}
					</Pressable>
				</View>
			)}
			{props.isTourModalVisible && (
				<TourModal
					title={tourModal.title}
					description={tourModal.description}
					visible={props.isTourModalVisible}
					skipTour={handleStop}
					startTour={props.start}
					setTourModal={props.handleCancelGuidedTour}
				/>
			)}

			{props.isTermsConditionsModalVisible && (
				<TermsConditionsModal
					visible={props.isTermsConditionsModalVisible}
					handleAgreeTermsConditions={props.handleAgreeTermsConditions}
					handleCancelTermsConditions={props.handleCancelTermsConditions}
				/>
			)}
			{props.isAnnouncementsModalVisible && (
				<AnnouncementsModal
					visible={props.isAnnouncementsModalVisible}
					handleAgreeAnnouncements={props.handleAgreeAnnouncements}
				/>
			)}
		</View>
	)
}

export default Sidebar
