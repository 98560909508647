import React, { useEffect, useState } from 'react'
import useSound from '../../Hooks/useSound'

export const PlaybackStatusContext = React.createContext()

export const PlaybackStatusProvider = ({ children }) => {
	const { currentPlayingTrack, sound, handleTrackChange } = useSound()
	const [playbackStatus, setPlaybackStatus] = useState({
		progressUpdateIntervalMillis: 500,
		positionMillis: 0,
		shouldPlay: false,
		rate: 1.0,
		shouldCorrectPitch: false,
		volume: 1.0,
		isMuted: false,
		isLooping: false,
		isLoaded: false,
		isPlaying: false
	})

	const handlePlaybackStatusUpdate = playbackStatus => {
		setPlaybackStatus(playbackStatus)
		if (playbackStatus.error) {
			console.log('Encountered a fatal error during playback:', playbackStatus.error)
		}
	}

	useEffect(() => {
		console.log('PlaybackStatusContext - setOnPlaybackStatusUpdate')
		if (currentPlayingTrack) {
			sound.current.setOnPlaybackStatusUpdate(handlePlaybackStatusUpdate)
		}
	}, [currentPlayingTrack])

	// Handle track end
	useEffect(() => {
		async function boot() {
			console.log('PlaybackStatusContext - handle track end')
			if (currentPlayingTrack) { 
				if (playbackStatus.didJustFinish) {
					await handleTrackChange('next')
				}
			}
		}
		boot()
	}, [playbackStatus.didJustFinish])

	return (
		<PlaybackStatusContext.Provider
			value={{
				playbackStatus
			}}
		>
			{children}
		</PlaybackStatusContext.Provider>
	)
}
