import Slider from '@react-native-community/slider'
import React from 'react'
import { View } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import useSound from '../../../../../Hooks/useSound'
import { colors } from '../../../../../Helpers/variables'


export default function VolumeControls() {
	const { currentPlayingTrack, handleChangeVolume, volume } = useSound()
	return (
		<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
			<Ionicons name="volume-low" size={20} color={currentPlayingTrack === null ? colors.secondary : 'white'} />
			<Slider
				style={{ marginHorizontal: 1, width: 170, height: 40 }}
				minimumValue={0}
				maximumValue={100}
				value={volume}
				minimumTrackTintColor={currentPlayingTrack === null ? colors.secondary : colors.accent}
				thumbTintColor={currentPlayingTrack === null ? colors.secondary : colors.accent}
				maximumTrackTintColor={currentPlayingTrack === null ? colors.secondary : colors.secondary}
				thumbStyle={{ width: 15, height: 15 }}
				onSlidingComplete={e => {
					handleChangeVolume(parseInt(e))
				}}
			/>
			<Ionicons name="volume-high" size={20} color={currentPlayingTrack === null ? colors.secondary : 'white'} />
		</View>
	)
}
