import React, { useEffect, useState } from 'react'
import { FlatList, Platform, Pressable, Text, View } from 'react-native'
import * as Sentry from 'sentry-expo'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { playlistSortByName } from '../../Helpers/sortArray'
import { TOP_TRACKS_URL, USER_PLAYLIST_URL, colors } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import MainTrackCard from './components/MainTrackCard'
import { Ionicons } from '@expo/vector-icons'

export default function TopTracksScreen() {
    const [items, setItems] = useState({
        UserTopTracks: [],
        MusicmindTopTracks: []
    })
    const api = useAxios()
    const [userPlaylists, setUserPlaylists] = useState([])
    const [showMyFaveSongs, setShowMyFaveSongs] = useState(true)
    const [showMMFaveSongs, setShowMMFaveSongs] = useState(true)
    const [notifModal, setNotifModal] = useState({
        visible: false,
        title: '',
        description: '',
        isDisappearing: false
    })

    const closeNotifModal = () => {
        setNotifModal({
            visible: false,
            title: '',
            description: '',
            isDisappearing: false
        })
    }

    const openNotifModal = (title, description, isDisappearing = false) => {
        setNotifModal({
            visible: true,
            title: title,
            description: description,
            isDisappearing: isDisappearing
        })
    }

    const updateUserPlaylists = (id, name, description, visibility) => {
        let userPlaylistsTemp = userPlaylists
        userPlaylistsTemp.push({ id: id, name: name, description: description, visibility: visibility })
        userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
        setUserPlaylists(userPlaylistsTemp)
    }

    useEffect(() => {
        async function boot() {
            await api
                .get(USER_PLAYLIST_URL)
                .then(({ data }) => {
                    data = playlistSortByName(data)
                    setUserPlaylists(data)
                })
                .catch(error => {
                    console.log(error)
                    if (Platform.OS === 'web') Sentry.Browser.captureException(error)
                    else Sentry.Native.captureException(error)
                })

            const { data } = await api.get(TOP_TRACKS_URL)
            setItems(data)
        }
        boot()
    }, [])

    const FlatListView = ({ tracksList, headerTitle, showMinimizeBtn, setShowMinimizeBtn }) => {
        return (
            <View>
                <Pressable style={{ padding: 10, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }} onPress={() => {
                    setShowMinimizeBtn(!showMinimizeBtn)
                }}>
                    <Text style={{ color: colors.accent, fontSize: 22 }}>{headerTitle}</Text>
                    <Ionicons
                        name={showMinimizeBtn ? 'chevron-up-circle-outline' : 'chevron-down-circle-outline'}
                        size={26}
                        style={{
                            marginLeft: 20,
                        }}
                        color="white"
                    />
                </Pressable>
                {
                    showMinimizeBtn &&
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        <FlatList
                            data={tracksList}
                            renderItem={({ item, index }) => (
                                <MainTrackCard
                                    track={item}
                                    userPlaylists={userPlaylists}
                                    updateUserPlaylists={updateUserPlaylists}
                                    openNotifModal={openNotifModal}
                                    closeNotifModal={closeNotifModal}
                                    item={item}
                                    key={item.id}
                                    index={index}
                                />
                            )}
                            keyExtractor={item => item.id}
                            //if the user pulls from the top of the screen, log out to the console:
                            onRefresh={() => console.log('refreshing')}
                            //if set to true, the UI will show a loading indicator and trigger data.
                            refreshing={false}
                            horizontal={true}
                        />
                    </View>
                }
            </View>
        )
    }

    return (
        <View style={{ marginBottom: 10, flex: 1 }}>
            {items.UserTopTracks && (
                <View>
                    {items.UserTopTracks.length > 0 && (
                        <FlatListView tracksList={items.UserTopTracks} headerTitle={'Dine favoritsage'} showMinimizeBtn={showMyFaveSongs} setShowMinimizeBtn={setShowMyFaveSongs} />
                    )}
                </View>
            )}
            {items.MusicmindTopTracks && (
                <View>
                    {items.MusicmindTopTracks.length > 0 && (
                        <FlatListView tracksList={items.MusicmindTopTracks} headerTitle={'MusicMinds favoritsange'} showMinimizeBtn={showMMFaveSongs} setShowMinimizeBtn={setShowMMFaveSongs} />
                    )}
                </View>
            )}

            {notifModal.visible && (
                <NotificationModal
                    title={notifModal.title}
                    description={notifModal.description}
                    visible={notifModal.visible}
                    closeNotifModal={closeNotifModal}
                    isDisappearing={notifModal.isDisappearing}
                />
            )}
        </View>
    )
}
