import { FlatList, Pressable, Text, View } from 'react-native'
import { colors } from '../../Helpers/variables'
import Card from './components/Card'

export default function SearchResultAlbum({ albums, navigation, mostRelated }) {
	return (
		<View>
			{mostRelated && albums && (
				<View>
					{mostRelated.length === 0 && albums.length === 0 && <Text style={{ color: 'white', marginLeft: 20, marginTop: 10 }}>Ingen data...</Text>}
				</View>
			)}

			{mostRelated && (
				<View>
					{mostRelated.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 20, color: colors.accent, fontWeight: 'bold', marginTop: 10 }}>
								Mest beslægtet
							</Text>
							<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								<FlatList
									data={mostRelated}
									renderItem={({ item, index }) => (
										<View
											style={{
												alignItems: 'center',
												justifyContent: 'flex-start',
												height: 170,
												margin: 5
											}}
										>
											<Pressable
												onPress={() =>
													navigation.navigate('AlbumTracks', { albumId: item.id, photo: item.Photo, title: item.album })
												}
												key={item.id}
											>
												<Card image={item.Photo} title={item.album} />
											</Pressable>
										</View>
									)}
									keyExtractor={item => item.id}
									//if the user pulls from the top of the screen, log out to the console:
									onRefresh={() => console.log('refreshing')}
									//if set to true, the UI will show a loading indicator and trigger data.
									refreshing={false}
									horizontal={true}
								/>
							</View>
						</View>
					)}
				</View>
			)}

			{albums && (
				<View>
					{albums.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 20, color: colors.accent, fontWeight: 'bold', marginTop: 10 }}>
								Mindre beslægtet
							</Text>
							<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								<FlatList
									data={albums}
									renderItem={({ item, index }) => (
										<View
											style={{
												alignItems: 'center',
												justifyContent: 'flex-start',
												height: 170,
												margin: 5
											}}
										>
											<Pressable
												onPress={() =>
													navigation.navigate('AlbumTracks', { albumId: item.id, photo: item.Photo, title: item.album })
												}
												key={item.id}
											>
												<Card image={item.Photo} title={item.album} />
											</Pressable>
										</View>
									)}
									keyExtractor={item => item.id}
									//if the user pulls from the top of the screen, log out to the console:
									onRefresh={() => console.log('refreshing')}
									//if set to true, the UI will show a loading indicator and trigger data.
									refreshing={false}
									horizontal={true}
								/>
							</View>
						</View>
					)}
				</View>
			)}
		</View>
	)
}
