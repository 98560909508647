import React from 'react'
import { Text, View, Alert } from 'react-native'
import { MenuItem } from 'react-native-material-menu'
import useSound from '../../../Hooks/useSound'
import { trackStyles } from './TrackStyles'

export const AddQueueTrack = ({ item, setMenuVisible, openNotifModal, closeNotifModal }) => {
	const { queueTrackList, setQueueTrackList, currentPlayingTrack } = useSound()
	const queueTrackLabel = 'Tilføj til kø'

	const addQueueTrackList = () => {
		let tempList = queueTrackList ? [...queueTrackList] : []

		const exists = tempList?.some(queue => queue.track.id === item.id );

		if (exists) {
			Alert.alert('Tilføj til kø', 'Sang allerede i køen')
			return
		} else {
			let newItem = {
				id: item.id,
				position: tempList.length + 1,
				track: item
			}

			tempList.push(newItem)
			
			Alert.alert('Tilføj til kø', 'Tilføjet')
			setQueueTrackList(tempList)
			setMenuVisible(false)
		}
	}

	return (
		currentPlayingTrack && (
			<View>
				<MenuItem
					style={trackStyles.menuTextView}
					onPress={() => {
						addQueueTrackList()
					}}
				>
					<Text style={trackStyles.menuTextView}>{queueTrackLabel}</Text>
				</MenuItem>
			</View>
		)
	)
}
