import { Pressable, Text, View, Platform, useWindowDimensions } from 'react-native'
import { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import useLayout from '../../../../Hooks/useLayout'

const LinkButton = ({ route, routeParams, title, icon, open, ...props }) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [showModal, setShowModal] = useState(false)

	useEffect(() => {
		handleResize()
	}, [width])

	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}

	const navigation = useNavigation()

	const { deviceType } = useLayout()
	return (
		<Pressable
			onPress={() => navigation.navigate(route, routeParams)}
			style={{ paddingVertical: 10, paddingHorizontal: 0 }}
			{...props}
		>
			<View style={{ flexDirection: 'row' }}>
				<Ionicons name={icon} size={deviceType === 1 ? (isSmallScreen ? 22 : 24) : 24} color={'#ffffff'} />
				{open && (
					<Text style={{ fontSize: Platform.OS === 'android' ? 20 : 16, color: '#ffffff', marginLeft: 5 }}>
						{title}
					</Text>
				)}
			</View>
		</Pressable>
	)
}

export default LinkButton
